import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const ModalDialog = ({ isOpen, setIsOpen, title, description, children }) => {
   return (
      <Transition show={isOpen} as={Fragment}>
         <Dialog
            onClose={() => setIsOpen(false)}
            className="fixed inset-0 z-10 overflow-y-auto"
         >
            {/*
             * The backdrop - rendered as a fixed sibling to the panel container
             * Rendered with fade-in/out transitions
             */}
            <Transition.Child
               as={Fragment}
               enter="ease-in duration-200"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-out duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div
                  className="fixed inset-0 bg-black/90 backdrop-blur-lg"
                  onClick={() => setIsOpen(false)}
                  aria-hidden="true"
               />
            </Transition.Child>

            <Transition.Child
               as={Fragment}
               enter="ease-in duration-200"
               enterFrom="opacity-0 scale-90"
               enterTo="opacity-100 scale-100"
               leave="ease-out duration-200"
               leaveFrom="opacity-100 scale-100"
               leaveTo="opacity-0 scale-90"
            >
               <div className="flex sm:items-top md:items-center justify-center min-h-screen sm:p-0 md:px-4 md:pt-4 text-center">
                  <div className="inline-block bg-modal rounded-xl text-left overflow-hidden shadow-xl transform transition-all md:mt-8 w-[100vw] md:max-w-[1200px]">
                     <div className="p-6">
                        <div className="flex flex-row justify-between w-full mt-2">
                           {/* Title and description */}
                           <div className="flex flex-col text-left w-full">
                              <Dialog.Title className="text-2xl font-header text-[#f3dec2]">
                                 {title}
                              </Dialog.Title>
                              <Dialog.Description className="text-base opacity-65 text-left text-gray-400 mt-2">
                                 {description}
                              </Dialog.Description>
                           </div>

                           {/* Close button */}
                           <div>
                              <button
                                 type="button"
                                 className="bg-transparent text-gray-300 hover:bg-white/15 focus:outline-none rounded-full transition duration-200 ease-in-out"
                                 onClick={() => setIsOpen(false)}
                              >
                                 <svg
                                    className="h-6 w-6 sm:m-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                 >
                                    <path
                                       strokeLinecap="round"
                                       strokeLinejoin="round"
                                       strokeWidth="2"
                                       d="M6 18L18 6M6 6l12 12"
                                    />
                                 </svg>
                              </button>
                           </div>
                        </div>
                     </div>

                     <div className="bg-modal px-4 pb-4">{children}</div>
                  </div>
               </div>
            </Transition.Child>
         </Dialog>
      </Transition>
   );
};

const WorkCard = ({ title, description, imageSrc, onClick }) => {
   const [isHovered, setIsHovered] = useState(false);
   const [isMouseDown, setIsMouseDown] = useState(false);

   const handleMouseEnter = () => {
      setIsHovered(true);
   };
   const handleMouseLeave = () => {
      setIsHovered(false);
   };

   const handleMouseDown = () => {
      setIsMouseDown(true);
   };

   const handleMouseUp = () => {
      setIsMouseDown(false);
   };

   return (
      <div
         className={`
            card w-full bg-base-100 shadow-xl rounded-xl
            max-w-[600px]
            transition-all duration-300 cursor-pointer
            ${isHovered ? "scale-110" : "scale-100"}
            ${isMouseDown ? "scale-95 duration-75" : "scale-100"}
            `}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}
         onMouseDown={handleMouseDown}
         onMouseUp={handleMouseUp}
         onClick={onClick}
         key={title}
      >
         {/* Card image */}
         <img src={imageSrc} alt={title} className="w-auto h-full rounded-xl" />

         {/* Overlay content */}
         <div
            className={`
                absolute w-full h-full transition-all duration-300
                
                bg-black/70 p-5
                ${isHovered ? "opacity-100" : "opacity-0"}
            `}
         >
            {/* Card title and description */}
            <div className="card-title absolute flex flex-col justify-start align-start text-left select-none">
               <div className="font-header text-2xl text-left w-full pb-0">
                  {title}
               </div>
               <div className="text-base text-left w-full opacity-75">
                  {description}
               </div>
            </div>
            {/* Centered eye icon */}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
               </svg>
            </div>
         </div>
      </div>
   );
};

export const Work = () => {
   const [openModal1, setOpenModal1] = useState(false);
   const [openModal2, setOpenModal2] = useState(false);
   const [openModal3, setOpenModal3] = useState(false);
   const [openModal4, setOpenModal4] = useState(false);
   const [openModal5, setOpenModal5] = useState(false);
   const [openModal6, setOpenModal6] = useState(false);

   const handleButtonClick = () => {
      // Directs the user to the PDF URL, opening it in a new tab or triggering a download
      window.open("Williams-Sonoma-ProcessBook.pdf", "_blank");
   };

   const works = [
      {
         title: "Williams-Sonoma",
         description: "Annual Report",
         imageSrc: "/images/WilliamsSonoma-mockup.jpg",
         onClick() {
            setOpenModal1(!openModal1);
         },
      },
      {
         title: "Meltbox",
         description: "Brand Identity/Packaging",
         imageSrc: "/images/meltbox-mock.jpg",
         onClick() {
            setOpenModal2(!openModal2);
         },
      },
      {
         title: "Jazz in the Valley",
         description: "Promotional",
         imageSrc: "/images/jitv-mock.jpg",
         onClick() {
            setOpenModal3(!openModal3);
         },
      },
      {
         title: "Frontier",
         description: "Web Design/Promotional",
         imageSrc: "/images/frontier-mockup.jpg",
         onClick() {
            setOpenModal4(!openModal4);
         },
      },
      {
         title: "Penn Valley Music Department",
         description: "Informational Booklet",
         imageSrc: "/images/projects/penn-valley-music-department/1.jpg",
         onClick() {
            setOpenModal5(!openModal5);
         },
      },
      //   {
      {
         title: "Self Portrait",
         description: "Digital Artwork",
         imageSrc: "/images/projects/self-portrait/1.jpg",
         onClick() {
            setOpenModal6(!openModal6);
         },
      },
   ];

   const imgContainerStyle = "px-0 py-2 md:p-4";
   const imgStyle = "w-full rounded-xl";
   const textStyle = "p-2 md:p-4 text-[#f3dec2] text-left";

   return (
      <div className="w-[calc(100dvw)] min-h-[calc(100dvh)] flex flex-col align-middle justify-start pt-[88px] bg-dark">
         <div className="flex flex-row w-[calc(100dvw)] px-4 md:px-8 py-8 justify-start">
            <h1 className="text-[#f3dec2] text-3xl md:text-5xl font-header">Work</h1>
         </div>
         {/* Responsive grid of cards with images and title overlays */}
         <div className="grid content-evenly md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-6 mx-[20px] md:mx-[40px] mb-[40px]">
            {/* Card component for each work item */}
            {works.map((work, index) => (
               <WorkCard
                  key={index}
                  title={work.title}
                  description={work.description}
                  imageSrc={work.imageSrc}
                  onClick={work.onClick}
               />
            ))}
         </div>

         {/* (Willams Sonoma) */}
         <ModalDialog
            isOpen={openModal1}
            setIsOpen={setOpenModal1}
            title={works[0].title}
            description={works[0].description}
         >
            {/* process book pdf button */}
            <div className={"w-fit p-0 py-2 md:p-4"}>
               <button
                  className="btn w-full select-none active:border-none focus:outline-none "
                  onClick={handleButtonClick}
               >
                  <div>Process Book</div>
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     strokeWidth={1.5}
                     stroke="currentColor"
                     className="w-6 h-6"
                  >
                     <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                     />
                  </svg>
               </button>
            </div>

            {/* Cover image */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/WilliamsSonoma-mockup.jpg"}
                  alt={"ws-mockup"}
                  className={imgStyle}
               />
            </div>

            {/* text body */}
            <p className={textStyle}>
               The objective for this project was to take information from the
               official 2022 Williams-Sonoma annual report and create a visually
               appealing booklet to send to shareholders.
               <br />
               <br />
               During my research, I found that the first Williams-Sonoma
               catalog came out in 1972. As the Williams-Sonoma catalogs are a
               paradigm to the Williams-Sonoma brand, I found it fitting to have
               the 2022 annual report be a nod to the 50 year anniversary of the
               catalog.
               <br />
               <br />
               The cover of the booklet reflects the cover of the first catalog,
               but with a modern twist. This theme is continued throughout the
               booklet with Williams-Sonoma’s classic neutral tones, accented by
               the green from the catalog cover. And, of course, images of
               products from the Williams-Sonoma family of brands are placed
               throughout the booklet to not only showcase the products to
               shareholders, but also to give it a catalog-like feel.
            </p>

            {/* image 1 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/willams-sonoma/Spread1.jpg"}
                  alt={"ws-spread-2"}
                  className={imgStyle}
               />
            </div>

            {/* image 2 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/willams-sonoma/Spread2.jpg"}
                  alt={"ws-spread-2"}
                  className={imgStyle}
               />
            </div>

            {/* image 3 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/willams-sonoma/Spread3.jpg"}
                  alt={"ws-spread-3"}
                  className={imgStyle}
               />
            </div>

            {/* image 4 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/willams-sonoma/Spread4.jpg"}
                  alt={"ws-spread-4"}
                  className={imgStyle}
               />
            </div>

            {/* image 5*/}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/willams-sonoma/Spread5.jpg"}
                  alt={"ws-spread-5"}
                  className={imgStyle}
               />
            </div>

            {/* image 6 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/willams-sonoma/Spread6.jpg"}
                  alt={"ws-spread-6"}
                  className={imgStyle}
               />
            </div>
         </ModalDialog>

         {/* Meltbox */}
         <ModalDialog
            isOpen={openModal2}
            setIsOpen={setOpenModal2}
            title={works[1].title}
            description={works[1].description}
         >
            {/* Cover image */}
            <div className={imgContainerStyle}>
               <img
                  src={works[1].imageSrc}
                  alt={works[1].title}
                  className={imgStyle}
               />
            </div>

            {/* text body */}
            <p className={textStyle}>
               Tiny skateboards with big personalities. Meltbox is a fingerboard
               brand, specializing in the 3D printed fingerboard accessories.
               Their product range includes wheels and cases.
               <br /> <br />I was responsible for building the company's brand
               identity including the brand logo, colors, packaging for the
               wheels, and thank you cards to include in their packages. When
               building the brand's identity, my goal was to reflect the
               3D-printed aspect of the company by creating an identity that was
               modern and clean.
            </p>

            {/* image 1 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/meltbox/1.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            {/* image 2 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/meltbox/2.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            {/* image 3 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/meltbox/3.jpg"}
                  alt={"mb-spread-3"}
                  className={imgStyle}
               />
            </div>

            {/* image 4 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/meltbox/4.jpg"}
                  alt={"mb-spread-4"}
                  className={imgStyle}
               />
            </div>

            {/* image 5 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/meltbox/5.jpg"}
                  alt={"mb-spread-5"}
                  className={imgStyle}
               />
            </div>
         </ModalDialog>

         {/* Jazz in the Valley */}
         <ModalDialog
            isOpen={openModal3}
            setIsOpen={setOpenModal3}
            title={works[2].title}
            description={works[2].description}
         >
            {/* Cover image */}
            <div className={imgContainerStyle}>
               <img
                  src={works[2].imageSrc}
                  alt={works[2].title}
                  className={imgStyle}
               />
            </div>

            {/* text body */}
            <p className={textStyle}>
               The purpose for this project was to create promotional material
               for MCC-Penn Valley’s annual jazz festival. <br /> <br />
               For these designs, I was inspired by vintage sheet music covers
               from the Jazz Age in the 1920’s, many of which incorporated
               moons. As for the color scheme, I chose to go with blue and
               yellow, as those are MCC-Penn Valley’s school colors. <br />{" "}
               <br />
               This project was part of a poster contest, in which I am proud to
               say my design was selected for the event.
            </p>

            {/* image 1 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/jazz-in-the-valley/Brochure-Side1.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            {/* image 2 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/jazz-in-the-valley/Brochure-Side2.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>
         </ModalDialog>

         {/* Frontier */}
         <ModalDialog
            isOpen={openModal4}
            setIsOpen={setOpenModal4}
            title={works[3].title}
            description={works[3].description}
         >
            {/* Cover image */}
            <div className={imgContainerStyle}>
               <img
                  src={works[3].imageSrc}
                  alt={works[3].title}
                  className={imgStyle}
               />
            </div>

            {/* text body */}
            <p className={textStyle}>
            For this project, I designed two pages of a website for Frontier, an upscale, rustic restaurant specializing in wild game and whole animal service. 
            The idea behind this design was to take the rough nature of the whole animal service and present it in a more sophisticated manner. <br />{" "}
            <br />
            These web pages were originally designed using InDesign and then converted to HTML/CSS.
            </p>

            {/* image 1 */}
            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/frontier/frontier-web-mock.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>
         </ModalDialog>

         {/* Penn Valley Music Department */}
         <ModalDialog
            isOpen={openModal5}
            setIsOpen={setOpenModal5}
            title={works[4].title}
            description={works[4].description}
         >
            {/* Cover image */}
            <div className={imgContainerStyle}>
               <img
                  src={works[4].imageSrc}
                  alt={works[4].title}
                  className={imgStyle}
               />
            </div>

            {/* text body */}
            <p className={textStyle}>
               The purpose for this project was toThis is an informational booklet created for MCC-Penn Valley’s music department to distribute to prospective students. 
               The booklet is meant to provide key information regarding MCC admissions and tuition, as well as department-specific information and imagery.  <br />{" "}
               <br />
               The booklet was designed as part of a class-wide campaign for the music department and follows the 
               branding theme decided on by our class, as well as MCC’s branding guidelines. 
            </p>

            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/penn-valley-music-department/2.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/penn-valley-music-department/3.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/penn-valley-music-department/4.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/penn-valley-music-department/5.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/penn-valley-music-department/6.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>

            <div className={imgContainerStyle}>
               <img
                  src={"/images/projects/penn-valley-music-department/7.jpg"}
                  alt={"mb-spread-2"}
                  className={imgStyle}
               />
            </div>
         </ModalDialog>

         {/* Self Portrait */}
         <ModalDialog
            isOpen={openModal6}
            setIsOpen={setOpenModal6}
            title={works[5].title}
            description={works[5].description}
         >
            {/* Cover image */}
            <div className={imgContainerStyle}>
               <img
                  src={works[5].imageSrc}
                  alt={works[5].title}
                  className={imgStyle}
               />
            </div>

            {/* text body */}
            <p className={textStyle}>
            This work is a digitally designed art piece created with photographs from a collaborative photoshoot for which I styled myself and modeled. 
            For this piece, I focused on symmetry and explored the use of different textures in Photoshop. <br /> <br />
            
            I am proud to say this piece took home first place at the MCC Student Art Show in 2023.

            (Original photography from Isabella Cordero and Laura Ingebrand) <br />{" "}
               
            </p>
         </ModalDialog>
      </div>
   );
};
