import React from 'react';
import SwiperCarousel from './SwiperCarousel';

export const SelectWorksSection = () => {
    return (
        <div className="w-full h-auto bg-dark pb-4" >
            {/* Project highlights - title */}
            <div className='flex flex-row w-full justify-end p-4 md:p-6'>
                <h1 className="text-[#f3dec2] text-3xl md:text-5xl font-header">Select Works</h1>
            </div>
            {/* Carousel container - Display an array of images, when hovered, display a caption */}
            <div className="relative max-w-full h-full">
                <SwiperCarousel/>
            </div>
            
            
        </div>
    );
}