import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import { Home } from './pages/Home';
import { Work } from './pages/Work';
import { Resume } from './pages/Resume';
import { Contact } from './pages/Contact';

// Main app container, render the navbar and page router
function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      

    </div>
  );
}

export default App;
