import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const contactLinks = {
   email: "mailto:chantelperezdesign@gmail.com",
   linkedin: "https://www.linkedin.com/in/chantel-perez-639175303/",
   instagram: "https://www.instagram.com/chantelperezdesign/",
};

// Responsive navbar with logo in the top left, ans social media icons in the top right
const Navbar = () => {
   // State to hold whether the page is scrolled or not
   const [isScrolled, setIsScrolled] = useState(false);

   useEffect(() => {
      const handleScroll = () => {
         // When the scroll is more than 100px, set isScrolled to true, else false
         const offset = window.scrollY;
         setIsScrolled(offset > 100);
      };

      // Add the event listener
      window.addEventListener("scroll", handleScroll);

      // Clean up the event listener
      return () => window.removeEventListener("scroll", handleScroll);
   }, []);

   // Social Icons
   const socialBtnStyle = `btn btn-outline btn-circle p-0 border-[#f3dec2] hover:bg-[#f3dec2] hover:bg-opacity-25 scale-90 md:scale-100`;
   const instagramIcon = () => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 md:h-5 md:w-5"
            fill="#f3dec2"
            viewBox="0 0 24 24"
         >
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
         </svg>
      );
   };

   const linkedInIcon = () => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 md:h-5 md:w-5 pb-[2px]"
            fill="#f3dec2"
            viewBox="0 0 24 24"
         >
            <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
         </svg>
      );
   };

   const emailIcon = () => {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#f3dec2"
            className="h-5 w-5 md:h-6 md:w-6"
         >
            <path
               strokeLinecap="round"
               strokeLinejoin="round"
               d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
            />
         </svg>
      );
   };

   // Function to render the nav links (side menu)
   const renderSideMenuButton = (text, link) => {
      return (
         <li>
            <Link to={link}>
               <button className="btn btn-ghost">{text}</button>
            </Link>
         </li>
      );
   };

   // Function to render the nav buttons given a label and route
   const renderNavButton = (text, link) => {
      return (
         <Link to={link}>
            <button className="btn btn-ghost normal-case text-lg text-[#f3dec2] hover:bg-[#f3dec2] hover:bg-opacity-25">
               {text}
            </button>
         </Link>
      );
   };

   return (
      <nav
         className={`fixed w-full z-10 bg-navbar transition-all duration-300 ease-in-out ${
            isScrolled ? "bg-opacity-90" : "bg-opacity-40"
         }`}
      >
         <div className="navbar py-2 px-4 md:py-3 ">
            <div className="navbar-start">
               {/* Mobile (side menu button) */}
               <div className="dropdown">
                  <div
                     tabIndex={0}
                     role="button"
                     className="btn btn-ghost lg:hidden p-2"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                     >
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth="2"
                           d="M4 6h16M4 12h8m-8 6h16"
                        />
                     </svg>
                  </div>
                  <ul
                     tabIndex={0}
                     className="menu menu-sm dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52"
                  >
                     {renderSideMenuButton("Work", "/work")}
                     {renderSideMenuButton("Resume", "/resume")}
                     {/* {renderSideMenuButton("Contact", "/contact")} */}
                  </ul>
               </div>

               {/* Desktop (logo) */}
               <button className="btn btn-square btn-ghost btn-md md:btn-lg w-16 md:w-20 mx-2 md:mx-0 h-auto py-[2px]">
                  <Link to="/">
                     <img src="logo.png" alt="logo" />
                  </Link>
               </button>
            </div>

            {/* Middle buttons */}
            <div className="navbar-center hidden lg:flex gap-4">
               {renderNavButton("Work", "/work")}
               {renderNavButton("Resume", "/resume")}
               {/* {renderNavButton("Contact", "/contact")} */}
            </div>

            {/* Right side buttons */}
            <div className="navbar-end">
               <div className="flex gap-1 md:gap-4 pr-3">
                  <a
                     href={contactLinks.instagram}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <button className={socialBtnStyle}>
                        {instagramIcon()}
                     </button>
                  </a>

                  <a
                     href={contactLinks.linkedin}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <button className={socialBtnStyle}>
                        {linkedInIcon()}
                     </button>
                  </a>

                  {/* Email Link Button */}
                  <a
                     href={`${contactLinks.email}?subject=Chantel Perez Website Inquiry`}
                  >
                     <button className={socialBtnStyle}>{emailIcon()}</button>
                  </a>
               </div>
            </div>
         </div>
      </nav>
   );
};

export default Navbar;
