import homeCover from "../home-hero-cover.jpeg";
/**
 * Component rendered at the top of the home page
 * - Background image
 * - Hero card content
 *
 * @returns React component
 */

export const HomeHeroSection = () => {
   return (
      <div
         className="flex items-end justify-start max-w-[100vw] h-[calc(100dvh)] bg-cover bg-no-repeat bg-[right_34%_bottom] sm:bg-center"
         style={{
            backgroundImage: `url(${homeCover})`,
         }}
      >
         <div className="hero-content text-left text-neutral-content h-[calc(100dvh)] md:h-[calc(55dvh)] bg-navbar/50 sm:bg-transparent items-end justify-start">
            {/* Hero card content */}
            <div className="w-full md:w-[50%] h-auto sm:p-10 rounded-xl">
               <h1 className="mb-5 text-[#f3dec2] text-4xl sm:text-5xl font-header">Hi, I'm Chantel.</h1>
               <p className="text-[#f3dec2] w-[80%] sm:w-full text-sm sm:text-lg">
                  I'm a graphic designer based in Kansas City, Missouri. My
                  creative journey began with a career as a freelance model,
                  which allowed me to work alongside some of the best creatives
                  in the city. It was through this work that I truly fell in
                  love with the creative process, from conceptualizing to
                  executing. Whether in front of a camera or behind a screen, I
                  am passionate about creating and the community that comes
                  along with it.
               </p>
               {/* <button className="btn bg-slate-800/30 btn-ghost btn-primary">See my work!</button> */}
            </div>
         </div>
      </div>
   );
};
