import { SelectWorksSection } from "../components/SelectWorksSection";
import { HomeHeroSection } from "../components/HomeHeroSection";

export const Home = () => {

   return (
      <div className="w-full h-[calc(100dvh)]">
        <HomeHeroSection />
        <SelectWorksSection />
      </div>
   );
};
