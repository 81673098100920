import React, { useRef, useCallback } from "react";

// Import Swiper React components, styles, and modules
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Mousewheel } from "swiper/modules";
import { Link } from "react-router-dom";

export default function SwiperCarousel() {
   const sliderRef = useRef(null);

   const handlePrev = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slidePrev();
   }, []);

   const handleNext = useCallback(() => {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
   }, []);
   // Render method for each swiper slide
   const renderSlide = (title, imgSrc, index) => {
      return (
         <SwiperSlide key={index}>
            <div className="relative">
            <img
               src={imgSrc}
               alt={`Slide ${index + 1}`}
               className="aspect-square rounded-lg"
            />
            <Link to="/work">
            <div className="absolute inset-0 bg-black opacity-0 hover:opacity-70 transition-opacity duration-300">
            <div className="card-title absolute flex flex-col justify-start align-start text-left select-none">
               <div className="font-header text-xl md:text-2xl text-left w-full p-5">
                  {title}
               </div>
               {/* <div className="text-base text-left w-full opacity-75">
                  {description}
               </div> */}
            </div>
            </div>
            </Link>
         </div>
         </SwiperSlide>
      );
   };
   return (
      <div className="flex flex-row w-full justify-evenly p-4">
         {/* Back button */}
         <div className="flex flex-col w-auto h-fill justify-center">
            <button
               className="btn btn-circle btn-ghost  text-[#f3dec2] translate-x-[-10px]"
               onClick={handlePrev}
            >
               {/* back icon */}
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-7 h-7"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
               </svg>
            </button>
         </div>
         <Swiper
            ref={sliderRef}
            onSlideChange={() => {
               /*...*/
            }}
            mousewheel={true}
            direction="horizontal"
            breakpoints={{
               640: {
                  slidesPerView: 1,
               },
               768: {
                  slidesPerView: 2,
               },
               1024: {
                  slidesPerView: 3,
               },
            }}
            spaceBetween={20}
            slidesPerGroup={1}
            centeredSlides={true}
            initialSlide={2}
            loop={true}
            navigation={{
               prevEl: ".swiper-button-prev",
               nextEl: ".swiper-button-disabled",
               disabledClass: "swiper-button-disabled",
            }}
            modules={[Navigation, Mousewheel]}
            className="mySwiper"
         >
            {renderSlide("Frontier", "/images/frontier-mockup.jpg", 0)}
            {renderSlide("Meltbox", "/images/meltbox-mock.jpg", 1)}
            {renderSlide("Williams-Sonoma", "/images/WilliamsSonoma-mockup.jpg", 2)}
            {renderSlide("Jazz in the Valley", "/images/jitv-mock.jpg", 3)}
         </Swiper>

         {/* Forward button */}
         <div className="flex flex-col w-auto h-fill justify-center">
            <button
               className="btn btn-circle btn-ghost  text-[#f3dec2] translate-x-[10px]"
               onClick={handleNext}
            >
               {/* forward icon */}
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
               </svg>
            </button>
         </div>
      </div>
   );
}
