
// arrow export component
export const Contact = () => {

    return (
        <div className="w-screen min-h-screen h-auto flex flex-col align-middle justify-start pt-[88px] bg-dark">
         <div className="flex flex-row w-screen justify-start p-10">
            <h1 className="text-[#f3dec2] text-4xl font-header">Contact</h1>
         </div>
         </div>
    );
}