
export const Resume = () => {

    return (
        <div className="min-h-screen h-auto flex flex-col align-middle justify-start pt-[88px] bg-dark">
         <div className="flex flex-row w-screen px-4 md:px-8 py-8 justify-start ">
            <h1 className="text-[#f3dec2] text-3xl sm:text-3xl md:text-5xl font-header">Resume</h1>
         </div>
         <div className="px-4 w-full flex flex-row justify-center">
                     <img
                        src={'/images/ChantelPerez-Resume.jpg'}
                        alt={'resume'}
                        className="sm:max-w-[95vw] md:w-4/5 lg:max-w-[1000px] rounded-2xl"
                     />
                  </div>
         </div>
    );
}